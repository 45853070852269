/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --cart-page-divider-background: var(--secondary-base-color);
    --cart-page-promo-background: #fff;
    --cart-page-table-head-background: var(--secondary-base-color);
    --desktop-loader-offset: calc(0px - var(--header-total-height) - var(--breadcrumbs-height) - 32px);
}

.CartPage {
    margin-block-end: var(--footer-total-height);

    @include mobile {
        --footer-totals-height: 123px;

        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;
    }

    &-Wrapper {
        margin-block-end: 100px;

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 360px;
            grid-column-gap: 20px;
        }

        @include tablet {
            grid-column-gap: 12px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-InitialLoaderContainer {
        inset-inline-start: 0;
        inset-block-start: 0;
        z-index: 10;
        position: fixed;

        @include desktop {
            max-width: 100vw;
            max-height: 100vh;
            width: 100vw;
            height: 100vh;
        }

        .Loader {
            margin: none;

            &-Scale {
                @include mobile {
                    position: fixed;
                    inset-block-start: auto;
                    inset-block-end: 50%;

                    &:dir(rtl) {
                        transform: translate(50%, 50%);
                    }

                    &:dir(ltr) {
                        transform: translate(-50%, 50%);
                    }
                }
            }
        }
    }

    &-Empty {
        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--expandable-content-divider-color);
            text-align: center;
        }
    }

    &-Summary {
        background: #F4F1EC;

        @include mobile {
            inset-inline-start: 0;
            width: 100%;
            margin: 50px 0px;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        width: 100%;
        text-align: center;
    }

    &-Promo,
    &-Total {
        min-height: 48px;

        @include mobile {
            min-height: 0;
            padding-block-end: 30px;
        }
    }

    &-Promo {
        order: 1;
        background: var(--primary-base-color);
        padding: 30px 80px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            padding-block-end: 30px;
            margin-block-end: 40px;
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--cart-page-divider-background);
            padding: 24px 12px;
            border-radius: var(--input-border-radius);

            @include mobile {
                margin-block: 12px;
                padding: 14px;
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 15% 3fr 1fr 1fr 1fr 5%;
        // background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 12px;
        grid-gap: 12px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    & &-Discount {// hack to obtain required specificity
        border-block-start: 0;

        @include mobile {
            border-block-start: 1px solid var(--expandable-content-divider-color);
            border-block-end: 0;
            padding: 30px 0;
        }

        @include desktop {
            margin-block-start: 32px;
            display: grid;
            grid-template-columns: 1fr 3fr;
            align-items: center;
        }

        .CartPage-ExpandableContentButton {
            svg {
                display: none;

                @include mobile {
                    display: block;
                }
            }
        }

        .ExpandableContent-Heading {
            font-weight: 300;
            font-size: 30px;
            letter-spacing: 0.04em;
        }

        .ExpandableContent-Content {
            .Form {
                align-items: center;
                width: 100%;

                .CartCoupon-Input {
                    width: 60%;
                    
                    @include mobile {
                        width: 100%;
                    }
                }

                input {
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid var(--color-black);
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: 0.04em;
                    color: var(--color-black);
                    width: 100%;
    
                    &::placeholder {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-Floating {
        @include desktop {
            display: flex;
            flex-direction: column;
            margin-block-start: 147px;
        }

        @include tablet {
            margin: 50px 0;
        }
    }

    &-Heading {
        margin-block: 24px;

        @include mobile {
            display: none;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 32px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
        }

        .ProductCard-ProductActions {
            display: flex;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    .CheckoutOrderSummary {
        .Loader {
            display: none;
        }
    }
    
    &-CheckoutButtonWrapper {
        padding: 30px;

        @include mobile {
            padding: 0px;
        }
    }

    &-ShippingPromoBlock {
        .CartPage-PromoText {
            font-size: 20px;
            color: var(--color-white);
            line-height: 24px;
            text-align: center;
            font-weight: 300;
        }
    }

    &-ProgressBarWrapper {
        width: 100%;
        background-color: var(--color-white);
        height: 15px;
        margin-block-start: 20px;
        border-radius: 25px;
        position: relative;
        overflow: hidden;
    }

    &-ProgressBar {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        height: 100%;
        border-radius: 25px;
        background-color: #E0B787;
    }
}
